import pureFilters from '@/lib/agreement/filters';
import isEmpty from 'lodash/isEmpty';

const defaultVal = (val, d) => (isEmpty(val) ? d : val);
const mkPartnerName = (d, key) => (agreementData) =>
	defaultVal(pureFilters.preferredName(agreementData[key]), d);

export const partner1Name = mkPartnerName('Party 1', 'partner1');
export const partner2Name = mkPartnerName('Party 2', 'partner2');

export default {
	props: {
		agreementData: {
			type: Object,
			required: true,
		},
	},
	computed: {
		partner1Name() {
			return partner1Name(this.agreementData);
		},
		partner2Name() {
			return partner2Name(this.agreementData);
		},
	},
};
