export default {
	created() {
		this.$on('conditional-group-reset', () => this.handleReset());
	},
	props: {
		resetUndefined: { type: Boolean, default: true },
	},
	methods: {
		handleReset() {
			if (this.resetUndefined) {
				this.$emit('input', undefined);
			} else {
				this.$emit('reset');
			}
		},
	},
};
