import add from 'lodash/add';
import toNumber from 'lodash/toNumber';

import calculateExclusion from '@/lib/calculateExclusion';
import { getPartnerName } from '@/lib/agreement/filters';

export default function FamilyResidence(
	{
		owned,
		address,
		value = 0,
		liabilities,
		exclusions,
		residenceOption,
		keep,
	},
	agreement
) {
	// const realtorFees =
	// 	residenceOption == 'split' && value > 0
	// 		? (6000 + (toNumber(value) - 100000) * 0.03) * 1.05
	// 		: 0;
	const sumOfLiabilities = liabilities.reduce(
		(total, { balance }) => add(total, toNumber(balance)),
		0
	);
	const fairMarketValue = toNumber(value) - sumOfLiabilities;
	const spouceOneExclusions = calculateExclusion(exclusions, '0');
	const spouceTwoExclusions = calculateExclusion(exclusions, '1');
	const shared = fairMarketValue - spouceOneExclusions - spouceTwoExclusions;
	let spouseOne = 0,
		spouseTwo = 0,
		enabled = false;
	if(agreement.agreement.propertyRecitals.length>0 && agreement.agreement.propertyRecitals.includes('Family Residence')){
		enabled=true
	}else{
		enabled=false
	}
	if (residenceOption == 'keep') {
		if (keep == '0') {
			spouseOne = shared;
		} else {
			spouseTwo = shared;
		}
	} else {
		const half = shared / 2;
		spouseOne = half;
		spouseTwo = half;
	}

	const ownership = getPartnerName(owned, agreement);

	return {
		description:
			address && Object.keys(address).length
				? `${address.street || ''}, ${address.city || ''}, ${
						address.province || ''
				  }`
				: '',
		ownership:
			ownership && ownership.toLowerCase().startsWith('joint')
				? 'Joint'
				: ownership,
		notes: [
			{ label: 'Fair Market Value', value },
			...liabilities.map(
				({ balance, lastFourDigits, lender, registered ,type }) => ({
					label: `${lender} ${
						registered === 'other' ? (type || '') : registered
					} ${
						lastFourDigits ? `[${lastFourDigits}]`:''
					}`,
					value: balance,
				})
			),
			{ label: 'Equity', value: fairMarketValue, class: 'border-top-1' },
		],
		value: fairMarketValue,
		spouceOneExclusions,
		spouceTwoExclusions,
		shared,
		spouseOne,
		spouseTwo,
		type:'family-residence',
		enabled
	};
}
