import { getPartnerName } from '@/lib/agreement/filters';
import formatMoney from '@/lib/formatMoney';

export default function RESP(
	{ name = '', lastFourDigits = '', balance = 0, ownership },
	agreement,
	initialIndex
) {
	let enabled = false;
	if(agreement.agreement.propertyRecitals.length>0 && agreement.agreement.propertyRecitals.includes('RESPs')){
		enabled=true
	}else{
		enabled=false
	}
	return {
		description: name
			? `${name} RESP ${lastFourDigits && `[${lastFourDigits}]`}`
			: '',
		ownership: getPartnerName(ownership, agreement),
		comments: `$${formatMoney(balance)}`,
		value: 0,
		spouceOneExclusions: '',
		spouceTwoExclusions: '',
		shared: '',
		spouseOne: '',
		spouseTwo: '',
		type:'RESP',
		enabled,
		initialIndex
	};
}
