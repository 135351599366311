<script>
import partnerChoices from '@/mixins/partnerChoices';
import filters from '@/lib/agreement/filters';

const fields = [
	{ key: 'amount', heading: 'Exclusion amount' },
	{ key: 'ownership', heading: 'Who is claiming exclusion?' },
	{ key: 'reason', heading: 'Reason for exclusion' },
];

export default {
	mixins: [partnerChoices],
	props: {
		agreementData: Object,
		value: {
                type: Array,
                default: () => [],
            },
		hideReason: Boolean,
		testid:String,
		index:Number,
		disabled:Boolean
	},
	computed: {
		fields() {
			return this.hideReason
				? fields.filter((field) => field.key !== 'reason')
				: fields;
		},	
	},
	methods: {
		spouse(ownership) {
			const otherOwner =
				ownership == 0
					? this.agreementData.partner2
					: this.agreementData.partner1;
			return ownership && this.agreementData
				? filters.preferredName(otherOwner)
				: '';
		},

		preferredName(ownership) {
			const ownerName =
				ownership == 0
					? this.agreementData.partner1
					: this.agreementData.partner2;
			return ownership ? filters.preferredName(ownerName) : '';
		},
	},
};
</script>

<template>
	<table-input v-model="value" :fields="fields" :testid="testid" :disabled="disabled">
		<template #amount="{ value, input, index}">
			<!-- <b-input-group size="sm" prepend="$">
				<b-form-input
					size="sm"
					:value="value"
					@change="input"
				/>
			</b-input-group> -->
			<input-group class="input-group"
			:value="value"
			size="sm"
			@input="input"
			dollar
			:testid="testid+'amount-'+index"
			:isExclusion="true"
			:class="[testid.includes('liability-exclusion') ? 'mb-width' : '']"
			:disabled="disabled"
			/>
		</template>

		<template #ownership="{ value, input, index }">
			<b-form-select :class="[testid.includes('liability-exclusion') ? 'mb-style' : '','select-class']"
				:options="[...partnerChoices]"
				:value="value"
				@input="input"
				:data-testid="testid+'claiming-'+index"
				:disabled="disabled"
			/>
		</template>

		<template #reason="{ value: reason, input, row: exclusion, index:index }">
			<b-form-select
			class="p-component select-class"
				:value="reason"
				size="sm"
				:options="[
					{
						text: 'it was owned before the relationship began',
						value: 'before the relationship between the parties began',
					},
					{
						text: 'acquired through inheritance',
						value: 'through an inheritance',
					},
					{ text: 'acquired by gift', value: 'as a gift' },
					{
						text: 'acquired by settlement/award of damages',
						value: `through a settlement or an award of damages; to a spouse as compensation for injury or loss that does not represent compensation for loss to both parties or lost income of ${spouse(
							exclusion.ownership
						)}`,
					},
					{
						text: 'acquired from insurance proceeds',
						value: `from money paid or payable under an insurance policy that does not represent compensation for loss to both parties or lost income of ${preferredName(
							exclusion.ownership
						)}`,
					},
					{
						text: 'agreed to exclude',
						value: 'excluded',
					},
					{
						text: 'acquired after separation',
						value: 'acquired after separation',
					},
					{
						text: 'the property is held in trust for another person',
						value:'in trust',
					},
				]"
				@input="input"
				:data-testid="testid+'reason-'+index"
				:disabled="disabled"
			/>
		</template>
	</table-input>
</template>
<style scoped>
.select-class{
    height: calc(1.5em + 1rem + 2px);
}
.select-class:enabled:hover{
	border-color: #2196F3;
}
.mb-style{
	@media (max-width: 391px) {
		width: 5.625rem;
	}
	@media (min-width: 391px) and (max-width: 480px) {
		width: 6.25rem;
	}
}

.mb-width{
	@media (max-width: 391px) {
		width: 7.375rem;
	}
	@media (min-width: 391px) and (max-width: 480px) {
		width: 8.063rem;
	}
}
</style>
