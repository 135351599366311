import toNumber from 'lodash/toNumber';

import calculateExclusion from '@/lib/calculateExclusion';
import { getPartnerName } from '@/lib/agreement/filters';

export default function Vehicle(
	{
		make = '',
		year = '',
		model = '',
		owner,
		value = 0,
		exclusions,
		plan = '',
		recipient,
	},
	agreement,
	initialIndex
) {
	const spouceOneExclusions = calculateExclusion(exclusions, '0');
	const spouceTwoExclusions = calculateExclusion(exclusions, '1');
	const shared = value - spouceOneExclusions - spouceTwoExclusions;
	const half = shared / 2;
	let spouseOne = 0,
		spouseTwo = 0,
		keeper = null,
		enabled = false;
	if(agreement.agreement.propertyRecitals.length>0 && agreement.agreement.propertyRecitals.includes('Vehicles')){
		enabled=true
	}else{
		enabled=false
	}
	if (plan.toLowerCase() == 'keep') {
		if (owner == '0') {
			spouseOne = shared;
			keeper = '0';
		} else if (owner == '1') {
			spouseTwo = shared;
			keeper = '1';
		} else {
			// joint
			spouseOne = half;
			spouseTwo = half;
			keeper = 'joint';
		}
	} else if (plan.toLowerCase() == 'transfer') {
		// swap
		if (recipient == '0') {
			spouseOne = shared;
			keeper = '0';
		} else if (recipient == '1') {
			spouseTwo = shared;
			keeper = '1';
		}
	} else {
		// sell & split
		spouseOne = half;
		spouseTwo = half;
		keeper = 'joint';
	}

	return {
		description: `${year} ${make} ${model}`,
		ownership: getPartnerName(owner, agreement),
		comments: '',
		value: toNumber(value),
		spouceOneExclusions,
		spouceTwoExclusions,
		shared,
		spouseOne,
		spouseTwo,
		keeper,
		type:'vehicle',
		enabled,
		initialIndex
	};
}
