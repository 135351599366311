<script>
import { BIconTrash, BIconPencilSquare } from 'bootstrap-vue';
import { firebaseApp } from '../../firebase';
import { mapState } from 'vuex';

export default {
	components: {
		BIconPencilSquare,
		BIconTrash,
	},
	props: {
		newLabel: {
			type: String,
			default: 'Add New Item',
		},
		newLabelForAIGeneration: {
			type: String,
			default: 'Add Clause Using AI'
		},
		value: Array,
		label: String,
		objectPrototype: {
			type: Function,
			default: () => {
				return {};
			},
		},
		testid:String,
		hideAddBtn:Boolean,
		isEditView:Boolean,
		itemIndexToShow:Number,
		forceAddNewItem:Boolean,
		disabled:Boolean
},
	computed:{
		...mapState(['agreement']),
	},
	methods: {
		add() {
			this.value.push(this.objectPrototype());
			setTimeout(() => {
				let element = this.$refs[this.testid+'list-item']
				const lastElement = element[element.length-1]
				if (lastElement) {
					lastElement.scrollIntoView({ behavior: 'smooth' });
				}
			}, 0);
			
		},
		removeItem(index) {
			if(this.isEditView){
				this.openConfirmModalForDeletion(index);
			} else
			this.value.splice(index, 1);
			if(this.newLabel === "Add Child"){
				this.agreement.parenting.holidayAndSpecialDaysList = this.agreement.parenting.holidayAndSpecialDaysList.filter((holiday)=> holiday.id !== `child${index}'sBirthday`)
			}
		},
		showItemCard(index){
			let result  = false;
			if(this.forceAddNewItem && this.value && this.value.length && (index == (this.value.length-1))){
				result = true;
			} else if(!this.forceAddNewItem && (this.itemIndexToShow == undefined || (this.itemIndexToShow!= undefined && this.itemIndexToShow == index))){
				result = true;
			}
			return result;
		},
		openConfirmModalForDeletion(index) {
			const newElement = this.$createElement;
			const messageConent = newElement(
				'div',
				{ class: ['text-center'] },
				[
					newElement(
						'p',
						{},
						`Are you sure you want to delete this property?
						 The details will be removed from your agreement, agreement builder and property schedule when you take this action.
						 This cannot be undone.`
					),
				]
			);
			this.$bvModal
				.msgBoxConfirm([messageConent], {
					title: 'Are you sure ?',
					size: 'md',
					buttonSize: 'sm',
					okVariant: 'primary',
					okTitle: 'Delete Property',
					cancelTitle: "Cancel",
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: true,
				})
				.then((accepted) => {
					if (accepted) {
						this.value.splice(index, 1);
							setTimeout(()=> window.location.reload(true), 1500)

					}
				})
				.catch((err) => {
					console.error('err->',err)
				});
		},
		async submitPrompt(){
			this.textGenerationOptions.responseGenerating = true;
			// console.log('this.requestPromptToAI==>',this.textGenerationOptions.requestPromptToAI)
			const createCompletionAPI = firebaseApp.functions('northamerica-northeast1').httpsCallable('openAIApis-createCompletion');
			// const prefixText = 'Using family law agreement sources, write a single legal clause, to be used in an existing agreement. '
			let promptToSend = null
			if(this.textGenerationOptions.isEditMode){
				let previousPrompts = this.value[this.textGenerationOptions.indexToEdit].prompt.map((obj)=>{
					return{
						...obj,
						content : this.prefixText + obj.content
					}
				})
				promptToSend = [...previousPrompts ,{role: 'user',content: this.prefixText + this.textGenerationOptions.requestPromptToAI}]
			}
			else{
				promptToSend = [{role: 'user',content: this.prefixText + this.textGenerationOptions.requestPromptToAI}]
			}
			const reqBody = {
				prompt: promptToSend,
				max_tokens:256,
				model:'gpt-4'
			}
			const linkResponse = await createCompletionAPI(reqBody).catch((err)=> { this.textGenerationOptions.responseGenerating = false; console.error('submitPrompt Error',err)});

			// const createCompletionAPI = firebaseApp.functions('northamerica-northeast1').httpsCallable('openAIApis-fetchAIModels');
			// const linkResponse = await createCompletionAPI().catch((err)=> console.error('submitPrompt Error',err));
			// console.log('submitPrompt linkResponse==>',linkResponse)
			
			this.textGenerationOptions.responseGenerating = false;
			if(linkResponse && linkResponse.data && linkResponse.data.choices && linkResponse.data.choices.length){
				this.textGenerationOptions.responseFromAI = linkResponse.data.choices[0].message.content ? linkResponse.data.choices[0].message.content.replace("\n", "") : '';
			}
		},
		confirmResponseAndClose(){
			let indexToChange = null;
			if(this.textGenerationOptions.isEditMode){
				indexToChange = this.textGenerationOptions.indexToEdit;

				if(this.value && this.value[indexToChange]){
					this.$set(this.value[indexToChange], 'text', this.textGenerationOptions.responseFromAI);
					this.value[indexToChange].prompt =[...this.value[indexToChange].prompt,{role: 'user', content: this.textGenerationOptions.requestPromptToAI},
					{
						role: 'assistant',
						content: this.textGenerationOptions.responseFromAI
					}]
				}
			} else {
				this.add();
				indexToChange = this.value.length - 1;
				if(this.value && this.value[indexToChange]){
					this.$set(this.value[indexToChange], 'text', this.textGenerationOptions.responseFromAI);
					this.value[indexToChange].prompt =[{role: 'user', content: this.textGenerationOptions.requestPromptToAI},
					{
						role: 'assistant',
						content: this.textGenerationOptions.responseFromAI
					}]
				}
			}
				// console.log('responseFromAI-->',this.textGenerationOptions.responseFromAI);
				// // console.log(this.value)
				// if(this.value && this.value[indexToChange]){
				// 	this.value[indexToChange].text = this.textGenerationOptions.responseFromAI;
				// 	this.value[indexToChange].prompt = this.textGenerationOptions.requestPromptToAI;
				// }
				// console.log('this.value==>',this.value);
				this.textGenerationOptions.showAIGenerationModal = false;
		},
		closeTextGenerationModal(){
			this.textGenerationOptions.showAIGenerationModal = false;
		},
		openTextGenerationModal(options){
			// console.log('opt',options)
			this.textGenerationOptions.showAIGenerationModal = true;
			if(options && options.edit){
				this.textGenerationOptions.isEditMode=true;
				if(options.details){
					this.textGenerationOptions.requestPromptToAI = options.details.prompt[options.details.prompt.length - 2].content;
					this.textGenerationOptions.responseFromAI = options.details.text;
				}
				this.textGenerationOptions.indexToEdit = options.index;
			} else {
				this.textGenerationOptions.isEditMode=false;
				this.textGenerationOptions.requestPromptToAI ='';
				this.textGenerationOptions.responseFromAI ='';
			}
		}
	},
	mounted(){
		if(this.forceAddNewItem){
			this.add();
		}
	},
	computed:{
		textGenerationModalHeading(){
			return (this.textGenerationOptions.isEditMode) ? 'Edit Clause using AI' : 'Generate Clause using AI'
		},
		textGenerationModalSubmitBtn(){
			return (this.textGenerationOptions.isEditMode) ? 'Save the resultant clause' : 'Add the resultant clause'
		}
	},
	data(){
		return {
			textGenerationOptions:{
				showAIGenerationModal:false,
				requestPromptToAI :'',
				responseFromAI: '',
				responseGenerating:false,
				isEditMode:false,
				indexToEdit:undefined
			},
			prefixText: 'using family law agreement sources, write a single legal clause, in modern and simple legal language, to be used in an existing agreement, that says '
		}
	}
};
</script>

<template>
	<fieldset :key="testid+ value.length">
		<legend v-if="label">
			{{ label }}
		</legend>
		<template v-for="(localItem, index) in value">
		<b-card class="mb-3" v-if="showItemCard(index)" :key="index" :id="'child-information-' + (index + 1)" :ref="testid+'list-item'">
			<b-button size="sm" class="float-right" variant="pastel-blue" @click="removeItem(index)" :data-testid="testid+'remove-button'" :disabled="disabled">
				<b-icon-trash />
			</b-button>
			<b-icon-pencil-square class="h2 mb-2 mr-4 float-right" variant="info" v-if="localItem.prompt" :data-testid="testid+'edit-button'" @click="openTextGenerationModal({edit:true,details:localItem,index:index})" :disabled="disabled" />
			
			<slot :item="localItem" :index="index" />
		</b-card>
		</template>
		<b-button variant="primary" class="add-clause-btn" @click="add" v-if="!hideAddBtn" :data-testid="testid+'add-button'" :disabled="disabled">{{ newLabel }}</b-button>
		<b-button variant="primary" class="add-clause-btn-ai" @click="openTextGenerationModal" v-if="!hideAddBtn && (newLabel == 'Add Clause' || newLabel == 'Add Clause to Recitals' || newLabel == 'Add Clause to Section')" :data-testid="testid+'ai-add-button'" :disabled="disabled">{{ newLabel + ` using AI`}}</b-button>


		<b-modal
                id="AIGenerationModal"
                v-model="textGenerationOptions.showAIGenerationModal"
                :title=textGenerationModalHeading
				size="lg"
            >    
                <p>What kind of clause would you like to generate. Please be specific about your requirement.</p>
				<!-- <b-row>
					<b-col> -->
						<b-form-input
							v-model="textGenerationOptions.requestPromptToAI"
							placeholder="Ex: Write a sample clause regarding the child support law in Canada under 100 words"
							:data-testid="testid +'-request-to-AI'"
						/>
						<b-button size="sm" variant="primary" class="my-2 mt-3" @click="submitPrompt()" data-testid="generate-button" :disabled="textGenerationOptions.responseGenerating || !textGenerationOptions.requestPromptToAI.trim()">
									<template v-if="textGenerationOptions.responseGenerating">
										<b-spinner small type="grow"></b-spinner>
										Generating...
									</template>
									<template v-else>
										Generate
									</template>
						</b-button>
					<!-- </b-col> -->
					<!-- <b-col>
						<b-button size="sm" variant="primary" @click="submitPrompt()" data-testid="cancel-button"> Generate </b-button>
					</b-col>
				</b-row> -->
				<p>Resultant clause :</p>
					<b-form-textarea
						v-model="textGenerationOptions.responseFromAI"
						class="ml-2 my-3"
						input-cols="6"
						rows="5"
      					max-rows="10"
						:disabled="textGenerationOptions.responseGenerating"
						:data-testid="testid +'-request-from-AI'">
					</b-form-textarea>
                <template #modal-footer="{}">
                    <b-button size="sm" variant="primary" @click="confirmResponseAndClose()" data-testid="add-clause-button" :disabled="textGenerationOptions.responseGenerating || !textGenerationOptions.responseFromAI.trim()"> {{ textGenerationModalSubmitBtn }} </b-button>
                    <b-button size="sm" variant="navy" @click="closeTextGenerationModal()" data-testid="cancel-button"> Cancel </b-button>
                </template>
            </b-modal>
	</fieldset>
</template>

<style scoped>

.bi-pencil-square {
	cursor: pointer;
	transition-duration: 0.5s;
	opacity: 1.0;
}

.add-clause-btn-ai{
	margin-left: 1rem;
}

@media (max-width: 768px) {
    .add-clause-btn-ai{
		margin-top: 0.75rem;
		margin-left: 0;
	}
	.add-clause-btn,.add-clause-btn-ai{
		font-size:0.8rem;
	}
}
</style>