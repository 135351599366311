import {
	Account,
	AdditionalProperty,
	Business,
	Corporation,
	FamilyResidence,
	Liability,
	Pension,
	RESP,
	RRSP,
	Vehicle,
	OtherProperty,
} from '@/lib/models';

function liabilities(agreement) {
	return agreement.liabilities && agreement.liabilities.length
		? agreement.liabilities
				.map((liability,initialIndex) => Liability(liability, agreement,initialIndex))
				// .filter(({ description }) => description.trim()) //Commented this line for screen freeze issue on quick editor from prop scheduler
		: [];
}
function vehicles(agreement) {
	return agreement.vehicles && agreement.vehicles.length
		? agreement.vehicles
				.map((vehicle,initialIndex) => Vehicle(vehicle, agreement,initialIndex))
				// .filter(({ description }) => description.trim()) //Commented this line for screen freeze issue on quick editor from prop scheduler
		: [];
}
function properties(agreement) {
	return [
		FamilyResidence(agreement.familyResidence, agreement),
		...(agreement.properties && agreement.properties.length
			? agreement.properties.map((property,initialIndex) =>
					AdditionalProperty(property, agreement,initialIndex)
			  )
			: []),
	]
	// .filter(({ description }) => description); //Commented this line for screen freeze issue on quick editor from prop scheduler
}
function accounts(agreement) {
	return agreement.accounts && agreement.accounts.length
		? agreement.accounts
				.map((account,initialIndex) => Account(account, agreement,initialIndex))
				// .filter(({ description }) => description.trim()) //Commented this line for screen freeze issue on quick editor from prop scheduler
		: [];
}
function businesses(agreement) {
	return [
		...(agreement.businesses && agreement.businesses.length
			? agreement.businesses.map((business,initialIndex) =>
					Business(business, agreement,initialIndex)
			  )
			: []),
		...(agreement.corporations && agreement.corporations.length
			? agreement.corporations.map((corp,initialIndex) => Corporation(corp, agreement,initialIndex))
			: []),
	]
	// .filter(({ description }) => description); //Commented this line for screen freeze issue on quick editor from prop scheduler
}

function pensions(agreement) {
	return agreement.pensions && agreement.pensions.length
		? agreement.pensions
				.map((pension,initialIndex) => Pension(pension, agreement,initialIndex))
				// .filter(({ description }) => description) //Commented this line for screen freeze issue on quick editor from prop scheduler
		: [];
}

function registeredAccounts(agreement) {
	return [
		...(agreement.registeredEducationSavingsAccounts
			? agreement.registeredEducationSavingsAccounts.map((resp,initialIndex) =>
					RESP(resp, agreement,initialIndex)
			  )
			: []),
		// ...(agreement.registeredRetirementSavingsAccounts
		// 	? agreement.registeredRetirementSavingsAccounts.map((rrsp) =>
		// 			RRSP(rrsp, agreement)
		// 	  )
		// 	: []),
		...onlyRRSPWithoutDiscounts(agreement),
		...onlyBeforeTaxPensions(agreement)

	]
	// .filter(({ description }) => description); //Commented this line for screen freeze issue on quick editor from prop scheduler
}

function onlyBusiness(agreement){
	return agreement.businesses && agreement.businesses.length
			? agreement.businesses
				.map((bus,initialIndex) => Business(bus, agreement,initialIndex))
				// .filter(({ description }) => description.trim()) //Commented this line for screen freeze issue on quick editor from prop scheduler
			: [];
}

function onlyCorporation(agreement,){
	return agreement.corporations && agreement.corporations.length
			? agreement.corporations
				.map((corp,initialIndex) => Corporation(corp, agreement,initialIndex))
				// .filter(({ description }) => description.trim()) //Commented this line for screen freeze issue on quick editor from prop scheduler
			: [];
}

function onlyRESP(agreement){
	return agreement.registeredEducationSavingsAccounts && agreement.registeredEducationSavingsAccounts.length
			? agreement.registeredEducationSavingsAccounts
				.map((resp,initialIndex) => RESP(resp, agreement,initialIndex))
				// .filter(({ description }) => description.trim()) //Commented this line for screen freeze issue on quick editor from prop scheduler
			: [];
}

function onlyRRSP(agreement){
	return agreement.registeredRetirementSavingsAccounts && agreement.registeredRetirementSavingsAccounts.length
			? agreement.registeredRetirementSavingsAccounts
				.map((rrsp,initialIndex) => RRSP(rrsp, agreement,initialIndex))
				// .filter(({ description }) => description.trim()) //Commented this line for screen freeze issue on quick editor from prop scheduler
			: [];
}

function onlyRRSPWithDiscounts(agreement){
	return agreement.registeredRetirementSavingsAccounts && agreement.registeredRetirementSavingsAccounts.length
			? agreement.registeredRetirementSavingsAccounts
				.map((rrsp,index) => RRSP(rrsp, agreement, index))
				// .filter(({ description, discountRRSPForTax }) => (description.trim() && discountRRSPForTax)) //Commented this line for screen freeze issue on quick editor from prop scheduler
				.filter(({ description, discountRRSPForTax }) => (discountRRSPForTax))
			: [];
}

function onlyAfterTaxPensions(agreement){
	return agreement.pensions && agreement.pensions.length
	? agreement.pensions
		.map((pension, initialIndex) => Pension(pension, agreement,initialIndex))
		.filter(({description, orderOfTax, partiesAssignValue}) => ((orderOfTax==="after-tax")|| (partiesAssignValue && !orderOfTax) || (!partiesAssignValue)))
	: [];
}

function onlyBeforeTaxPensions(agreement){
	return agreement.pensions && agreement.pensions.length
	? agreement.pensions
		.map((pension, initialIndex) => Pension(pension, agreement,initialIndex))
		.filter(({description, orderOfTax}) => (orderOfTax=="before-tax"))
	: [];
}

function onlyRRSPWithoutDiscounts(agreement){
	return agreement.registeredRetirementSavingsAccounts && agreement.registeredRetirementSavingsAccounts.length
			? agreement.registeredRetirementSavingsAccounts
				.map((rrsp,index) => RRSP(rrsp, agreement, index))
				// .filter(({ description , discountRRSPForTax}) => (description.trim() && !discountRRSPForTax)) //Commented this line for screen freeze issue on quick editor from prop scheduler
				.filter(({ description , discountRRSPForTax}) => (!discountRRSPForTax))
			: [];
}

function otherProperties(agreement) {
	return agreement.otherProperties && agreement.otherProperties.length
		? agreement.otherProperties
				.map((otherProperty,initialIndex) => OtherProperty(otherProperty, agreement,initialIndex))
				// .filter(({ description }) => description.trim()) //Commented this line for screen freeze issue on quick editor from prop scheduler
		: [];
}


function allProperty(agreement) {
	return [
		...properties(agreement),
		...vehicles(agreement),
		...accounts(agreement),
		// ...businesses(agreement),
		...onlyBusiness(agreement),
		...onlyCorporation(agreement),
		// ...pensions(agreement),
		...onlyRRSPWithDiscounts(agreement),
		...onlyAfterTaxPensions(agreement),
		...otherProperties(agreement),
	];
}

function allPropertyExclusions(agreement) {
	return [...allProperty(agreement), ...liabilities(agreement)];
}

export {
	accounts,
	allProperty,
	allPropertyExclusions,
	businesses,
	onlyBusiness,
	onlyCorporation,
	liabilities,
	pensions,
	properties,
	registeredAccounts,
	onlyRESP,
	onlyRRSP,
	onlyRRSPWithDiscounts,
	onlyRRSPWithoutDiscounts,
	vehicles,
	onlyAfterTaxPensions,
	onlyBeforeTaxPensions,
	otherProperties,
};
