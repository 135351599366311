import toNumber from 'lodash/toNumber';

import calculateExclusion from '@/lib/calculateExclusion';
import { getPartnerName, getAccountNumber } from '@/lib/agreement/filters';

export default function Account(
	{
		institutionName = '',
		type = '',
		lastFourDigits = '',
		ownership,
		balance = 0,
		exclusions,
		plan,
		recipient,
	},
	agreement,
	initialIndex
) {
	const spouceOneExclusions = calculateExclusion(exclusions, '0');
	const spouceTwoExclusions = calculateExclusion(exclusions, '1');
	const shared = balance - spouceOneExclusions - spouceTwoExclusions;
	let spouseOne = 0,
		spouseTwo = 0,
		keeper = null,
		enabled = false;
	if(agreement.agreement.propertyRecitals.length>0 && agreement.agreement.propertyRecitals.includes('Financial Accounts')){
		enabled=true
	}else{
		enabled=false
	}
	if (plan === 'keep') {
		if (ownership == '0') {
			spouseOne = shared;
			keeper = '0';
		} else if (ownership == '1') {
			spouseTwo = shared;
			keeper = '1';
		} else {
			const half = shared / 2;
			spouseOne = half;
			spouseTwo = half;
			keeper = 'joint';
		}
	} else if (plan === 'transfer') {
		if (recipient == '0') {
			spouseOne = shared;
			keeper = '0';
		} else if (recipient == '1') {
			spouseTwo = shared;
			keeper = '1';
		}
	} else {
		const half = shared / 2;
		spouseOne = half;
		spouseTwo = half;
		keeper = 'joint';
	}

	return {
		description: `${institutionName} ${type} ${getAccountNumber({
			lastFourDigits,
		})}`.trim(),
		ownership: getPartnerName(ownership, agreement),
		comments: '',
		value: toNumber(balance),
		spouceOneExclusions,
		spouceTwoExclusions,
		shared,
		spouseOne,
		spouseTwo,
		keeper,
		type:'accounts',
		enabled,
		initialIndex
	};
}
