import calculateExclusion from '@/lib/calculateExclusion';
import { getPartnerName } from '@/lib/agreement/filters';

export default function Liability(
	{
		institutionName = '',
		type = '',
		lastFourDigits = '',
		owner,
		balance = 0,
		exclusions,
		responsible,
	},
	agreement,
	initialIndex
) {
	const spouceOneExclusions = calculateExclusion(exclusions, '0');
	const spouceTwoExclusions = calculateExclusion(exclusions, '1');
	const shared = balance - spouceOneExclusions - spouceTwoExclusions;
	let spouseOne = 0,
		spouseTwo = 0,
		keeper = null;
	// if (owner == '0' || responsible == '0') {
	// 	spouseOne = shared;
	// 	keeper = '0';
	// } else if (owner == '1' || responsible == '1') {
	// 	spouseTwo = shared;
	// 	keeper = '1';
	// } 
	if (responsible == '0' && (owner == '0' || owner == '1' || owner == 'Joint')) {
		spouseOne = shared;
		keeper = '0';
	} else if (responsible == '1' && (owner == '0' || owner == '1' || owner == 'Joint')) {
		spouseTwo = shared;
		keeper = '1';
	} else {
		// joint
		const half = shared / 2;
		spouseOne = half;
		spouseTwo = half;
		keeper = 'joint';
	}

	return {
		description: `${institutionName} ${type} ${
			lastFourDigits && `[${lastFourDigits}]`
		}`,
		ownership: getPartnerName(owner, agreement),
		comments: '',
		value: balance,
		spouceOneExclusions,
		spouceTwoExclusions,
		shared,
		spouseOne,
		spouseTwo,
		keeper,
		initialIndex
	};
}
