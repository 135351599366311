<script>
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
export default {
	data() {
		return {
			localValue: undefined,
			format:'MM-dd-yyyy'
		};
	},
	props: ['selectedTime', 'testid'],
	components:{VueTimepicker},
	computed:{
		selectTime:{
			get: function () {
				return this.selectedTime;
			},
			set: function (value) {
				this.selectedTime = value;
			},
		}
		// getSelectedTime(){
		// 	return this.selectedTime;
		// },

	},
	methods:{
		timeChangeEvent(eventData){
			console.log(eventData)
		}
	}
};
</script>

<template>
	<!-- <b-time
		v-model="selectedTime"
		class="border rounded p-2 mt-2"
		locale="en"
		hide-header
		:data-testid="testid"
	></b-time> -->
	<vue-timepicker format="h:mm a" v-model="selectTime" @change="timeChangeEvent" manual-input></vue-timepicker>
</template>
