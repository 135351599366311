import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/analytics';
import 'firebase/performance';
import 'firebase/remote-config';
import 'firebase/storage';
import 'firebase/database';
import 'firebaseui';

import defaultRemoteConfig from './defaultRemoteConfig';

const firebaseConfig = {
	apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
	authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
	projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.VUE_APP_FIREBASE_APP_ID,
	measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({
	ignoreUndefinedProperties: true,
	merge: true,
});

// utils
const auth = firebase.auth();
const db = firebase.firestore();
const perf = firebase.performance();
const functions = firebase.functions();
const remoteConfig = firebase.remoteConfig();
const realtimeDB = firebase.database()
let analytics = {};
const storage = firebase.storage();
const firebaseApp = firebase.app();

// default remote configuration
remoteConfig.defaultConfig = defaultRemoteConfig;
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

if (process.env.NODE_ENV === 'development') {
	// Note that the Firebase Web SDK must connect to the WebChannel port
	// db.useEmulator('localhost', 8080);
	// // Point to the RTDB emulator running on localhost.
	// realtimeDB.useEmulator("localhost", 9000);

	// firebase.auth().useEmulator('http://localhost:9099/');

	// functions.useEmulator('localhost', 5001);
	// storage.useEmulator('localhost', 9199);

	// remoteConfig.settings.minimumFetchIntervalMillis = 0;
}

if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_TEST) {
	perf.instrumentationEnabled = false;
	perf.dataCollectionEnabled = false;
	analytics.logEvent = () => {};
	analytics.setUserProperties = () => {};
}

if (process.env.NODE_ENV === 'production') {
	analytics = firebase.analytics();
}

// collection references
const agreementsCollection = db.collection('agreements');
const customersCollection = db.collection('customers');
const plansCollection = db.collection('plans');
const usersCollection = db.collection('users');
const externalUsersCollection = db.collection('externalUsers');
const roleLevelCollection = db.collection('roleLevels');

// providers
const googleProvider = firebase.auth.GoogleAuthProvider.PROVIDER_ID;

// export utils/refs
export {
	analytics,
	db,
	auth,
	functions,
	perf,
	agreementsCollection,
	customersCollection,
	plansCollection,
	usersCollection,
	externalUsersCollection,
	roleLevelCollection,
	googleProvider,
	remoteConfig,
	storage,
	realtimeDB,
	firebaseApp
};
