<script>
import debounce from 'lodash/debounce';

export default {
	props: {
		value: Array,
		newLabel: {
			type: String,
			default: 'Add Clause',
		},
		newLabelForAIGeneration: {
			type: String,
			default: 'Add Clause Using AI',
		},
		label: String,
		testid:String,
		title: String,
		disabled:Boolean
	},
	data(){
		return{
			saving: false
		}
	},
	methods:{
		handleChange: debounce(function(clause) {
			this.$set(clause, 'saving', true);

			setTimeout(() => {
				this.$set(clause, 'saving', false);
			}, 500);
		}, 700),
	}
};
</script>

<template>
	<div class="py-2">
		<h5 class="add-clause-heading">{{title}}</h5>
		<list-item
			v-slot="{ item: clause, index: index }"
			v-model="value"
			:new-label="newLabel"
			:new-label-for-AI-generation="newLabelForAIGeneration"
			:object-prototype="
				() => {
					return {};
				}
			"
			:testid="testid"
			:disabled="disabled"
		>
			<b-form-textarea
				v-model="clause.text"
				placeholder="Add a custom clause"
				rows="2"
				max-rows="4"
				size="sm"
				style="width: 90%"
				no-resize
				trim
				:data-testid="testid + index"
				class="custom-scroll scroll-small text-area-scroll additional-clause-text"
				:disabled="disabled"
				@input="handleChange(clause)"
			/>
			<b-badge
				v-if="clause.text"
				pill
				variant="light"
				class="p-2 mt-2 saving-btn"
				style="width: 64px"
				>{{ clause.saving ? 'Saving...' : 'Saved' }}</b-badge
			>
		</list-item>
	</div>
</template>
