import add from 'lodash/add';
import isNumber from 'lodash/isNumber';
import subtract from 'lodash/subtract';
import toNumber from 'lodash/toNumber';

import { allProperty, liabilities, registeredAccounts } from '@/lib/property';

function sumExclusionsToBeRepaid(exclusions = [], spouse) {
	const property =
		spouse == '0' ? 'spouceTwoExclusions' : 'spouceOneExclusions';
	const filtered = exclusions.filter(
		({
			value,
			shared,
			spouseOne,
			spouseTwo,
			spouceOneExclusions,
			spouceTwoExclusions,
		}) =>
			(spouse == '0' &&
				shared == spouseOne &&
				value != spouceTwoExclusions &&
				spouceTwoExclusions > 0) ||
			(spouse == '1' &&
				shared == spouseTwo &&
				value != spouceOneExclusions &&
				spouceOneExclusions > 0)
	);

	return filtered.reduce(
		(total, asset) =>
			add(
				total,
				isNumber(asset[property]) ? toNumber(asset[property]) : 0
			),
		0
	);
}

function sumRegistered(registeredAccounts = [], property) {
	return registeredAccounts.reduce(
		(total, account) => add(total, toNumber(account[property])),
		0
	);
}

// function sumAssets(assets = [], property) {
// 	let returnValue
// 	let assetValue=0
// 	if(property=='shared' || property=='value'){
// 		for(let i=0;i<assets.length;i++){
// 			if(assets[i].type!='corporation' || (assets[i].type=='corporation' && assets[i].details.length==0))
// 				{	
// 					assetValue += isNumber(assets[i].shared) ? toNumber(assets[i].shared) : 0
// 				}
// 				if(assets[i].type=='corporation' && assets[i].details.length!=0){
					
// 					for(let j=0; j<assets[i].details.length; j++){
// 						// assetValue=assetValue+assets[i].details[j].shared
// 						let sharedValue=isNumber(assets[i].details[j].shared) ? toNumber(assets[i].details[j].shared) : 0
// 						assetValue = assetValue + sharedValue
// 					}
// 				}
// 		}
// 		returnValue=assetValue
// 	}else{
// 		returnValue=assets.reduce(
// 			(total, asset) =>
// 				add(
// 					total,
// 					isNumber(asset[property]) ? toNumber(asset[property]) : 0
// 				),
// 			0
// 		);
// 	}
// 	return returnValue
// 	// return assets.reduce(
// 	// 	(total, asset) =>
// 	// 		add(
// 	// 			total,
// 	// 			isNumber(asset[property]) ? toNumber(asset[property]) : 0
// 	// 		),
// 	// 	0
// 	// );
// }

function sumAssets(assets = [], property) {
	let totalValue = 0;
	for (const asset of assets) {
		if (asset.type === 'corporation' && asset.details.length > 0) {
			for (const detail of asset.details) {
				totalValue += extractPropertyValue(detail[property]);
			}
		} else {
			totalValue += extractPropertyValue(asset[property]);
		}
	}

	return totalValue;
}

function extractPropertyValue(value) {
	return isNumber(value) ? toNumber(value) : 0;
}

function sumLiabilities(liabilities = [], property) {
	return liabilities.reduce(
		(total, liability) => add(total, toNumber(liability[property])),
		0
	);
}

// I think we could optimize this by returning all of the sums once.
function getAssetsSummary(assets = []) {
	assets = assets.filter(asset => asset.enabled === true)
	return {
		value: sumAssets(assets, 'value'),
		spouceOneExclusions: sumAssets(assets, 'spouceOneExclusions'),
		spouceTwoExclusions: sumAssets(assets, 'spouceTwoExclusions'),
		shared: sumAssets(assets, 'shared'),
		spouseOne: sumAssets(assets, 'spouseOne'),
		spouseTwo: sumAssets(assets, 'spouseTwo'),
	};
}

function getLiabilitySummary(liabilities = []) {
	return {
		value: sumLiabilities(liabilities, 'value'),
		spouceOneExclusions: sumLiabilities(liabilities, 'spouceOneExclusions'),
		spouceTwoExclusions: sumLiabilities(liabilities, 'spouceTwoExclusions'),
		shared: sumLiabilities(liabilities, 'shared'),
		spouseOne: sumLiabilities(liabilities, 'spouseOne'),
		spouseTwo: sumLiabilities(liabilities, 'spouseTwo'),
	};
}

function getRegisteredAssetSummary(registeredAccounts = []) {
	registeredAccounts = registeredAccounts.filter(registeredAccount => registeredAccount.enabled === true)
	return {
		value: sumRegistered(registeredAccounts, 'value'),
		spouceOneExclusions: sumRegistered(
			registeredAccounts,
			'spouceOneExclusions'
		),
		spouceTwoExclusions: sumRegistered(
			registeredAccounts,
			'spouceTwoExclusions'
		),
		shared: sumRegistered(registeredAccounts, 'shared'),
		spouseOne: sumRegistered(registeredAccounts, 'spouseOne'),
		spouseTwo: sumRegistered(registeredAccounts, 'spouseTwo'),
	};
}

function getNetSummary(assets = [], liabilities = []) {
	const assetsSummary = getAssetsSummary(assets);
	const liabilitySummary = getLiabilitySummary(liabilities);
	return {
		value: subtract(assetsSummary.value, liabilitySummary.value),
		spouceOneExclusions: subtract(
			assetsSummary.spouceOneExclusions,
			liabilitySummary.spouceOneExclusions
		),
		spouceTwoExclusions: subtract(
			assetsSummary.spouceTwoExclusions,
			liabilitySummary.spouceTwoExclusions
		),
		shared: subtract(assetsSummary.shared, liabilitySummary.shared),
		spouseOne: subtract(
			assetsSummary.spouseOne,
			liabilitySummary.spouseOne
		),
		spouseTwo: subtract(
			assetsSummary.spouseTwo,
			liabilitySummary.spouseTwo
		),
	};
}

function propertyEqualization(assets = [], liabilities = []) {
	assets = assets.filter(asset => asset.enabled === true)
	const propertyExclusions = [...assets, ...liabilities];
	const netSummary = getNetSummary(assets, liabilities);

	const spouseOne = netSummary.spouseOne - netSummary.shared / 2;
	const spouseTwo = netSummary.spouseTwo - netSummary.shared / 2;
	const spouseOneRepayExclusion = sumExclusionsToBeRepaid(
		propertyExclusions,
		'0'
	);
	const spouseTwoRepayExclusion = sumExclusionsToBeRepaid(
		propertyExclusions,
		'1'
	);

	return {
		spouseOne,
		spouseTwo,
		spouseOneRepayExclusion,
		spouseTwoRepayExclusion,
		spouseOneOverall:
			spouseOne + spouseOneRepayExclusion - spouseTwoRepayExclusion,
		spouseTwoOverall:
			spouseTwo + spouseTwoRepayExclusion - spouseOneRepayExclusion,
	};
}

function registeredPropertyEqualization(registeredAccounts) {
	const registeredAssetSummary =
		getRegisteredAssetSummary(registeredAccounts);

	const spouseOne =
		registeredAssetSummary.spouseOne - registeredAssetSummary.shared / 2;
	const spouseTwo =
		registeredAssetSummary.spouseTwo - registeredAssetSummary.shared / 2;
	const spouseOneRepayExclusion = sumExclusionsToBeRepaid(
		registeredAccounts,
		'0'
	);
	const spouseTwoRepayExclusion = sumExclusionsToBeRepaid(
		registeredAccounts,
		'1'
	);

	return {
		spouseOne,
		spouseTwo,
		spouseOneRepayExclusion,
		spouseTwoRepayExclusion,
		spouseOneOverall:
			spouseOne + spouseOneRepayExclusion - spouseTwoRepayExclusion,
		spouseTwoOverall:
			spouseTwo + spouseTwoRepayExclusion - spouseOneRepayExclusion,
	};
}

function getPropertyEqualization(agreement) {
	const property = allProperty(agreement);
	const liabilityProperty = liabilities(agreement);
	return propertyEqualization(property, liabilityProperty);
}

function getRegisteredPropertyEqualization(agreement) {
	const registeredProperty = registeredAccounts(agreement);
	return registeredPropertyEqualization(registeredProperty);
}

export {
	getAssetsSummary,
	getLiabilitySummary,
	getNetSummary,
	getPropertyEqualization,
	getRegisteredPropertyEqualization,
	propertyEqualization,
	getRegisteredAssetSummary,
	registeredPropertyEqualization,
	sumExclusionsToBeRepaid,
	sumRegistered,
	sumAssets,
	sumLiabilities,
};
