import AdditionalClauses from './AdditionalClauses';
import AddressInputGroup from './AddressInputGroup';
import CheckboxItem from './CheckboxItem';
import CheckGroup from './CheckGroup';
import ChildInputGroup from './ChildInputGroup';
import ConditionalCheckGroup from './ConditionalCheckGroup';
import ConditionalGroup from './ConditionalGroup';
import DateInput from './DateInput';
import ExclusionTable from './ExclusionTable';
import InputGroup from './InputGroup';
import ListItem from './ListItem';
import PartnerChoice from './PartnerChoice';
import PartyNameInputGroup from './PartyNameInputGroup';
import RadioGroup from './RadioGroup';
import TableInput from './TableInput';
import YesNoChoice from './YesNoChoice';
import CorporationDivisionTable from './CorporationDivisionTable';
import TimeInput from './TimeInput';


export default {
	AdditionalClauses,
	AddressInputGroup,
	CheckboxItem,
	CheckGroup,
	ChildInputGroup,
	ConditionalCheckGroup,
	ConditionalGroup,
	DateInput,
	ExclusionTable,
	InputGroup,
	ListItem,
	PartnerChoice,
	PartyNameInputGroup,
	RadioGroup,
	TableInput,
	YesNoChoice,
	CorporationDivisionTable,
	TimeInput
};
