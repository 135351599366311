<script>
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';

import differenceInCalendarYears from 'date-fns/differenceInCalendarYears';

import { preferredName } from '@/lib/agreement/filters';
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import agreementValidation from '@/mixins/agreementValidation'

export default {
	props: {
		value: Object,
		testid: String,
		disabled: Boolean
	},
	mixins:[validationMixin,agreementValidation],
	computed: {
		age() {
			const birthdate = parseISO(this.value.birthdate);

			return (
				isValid(birthdate) &&
				differenceInCalendarYears(new Date(), birthdate)
			);
		},
		childPreferredName() {
			return preferredName(this.value);
		},
	},
	methods:{
		validateState(name) {
			const { $dirty, $error } = this.$v.value[name];
			return $dirty ? !$error : null;
		},
		triggerValidation(){
			this.$v.value.$touch()
			this.$refs['child-name-ref'].triggerValidation();
			this.$refs['child-pronouns-ref'].triggerValidation();

		}
	},
	validations:{
		value:{
			birthdate:{
				required
			}
		}
	}
};
</script>

<template>
	<div class="app-input-group">
		<party-name-input-group
			v-model="value"
			label="Child's Name"
			class="mb-3 child-name-label"
			:testid="testid"
			:disabled="disabled"
			ref="child-name-ref"
		/>
		<date-input v-model="$v.value.birthdate.$model" label="Birthdate" :testid="testid + '-dob'" :isDateFilled="validateState('birthdate')" :isValidationOn="true" :disabled="disabled"/>
		<div
			v-if="$v.value.birthdate.$error"
			class="invalid-feedback d-block col-12"
		>
			This is a required field.
		</div>
		<radio-group
			v-model="value.pronouns"
			class="mb-3"
			select
			input-cols="5"
			label="Pronouns"
			:options="[
				{ text: 'He/Him', value: 'male' },
				{ text: 'She/Her', value: 'female' },
				{ text: 'They/Them', value: 'neutral' },
			]"
			:testid="testid + '-pronouns'"
			:disabled="disabled"
			:validation="true"
			ref="child-pronouns-ref"
		/>

		<conditional-group
			class="mt-3"
			:condition="age > 19"
			@reset="
				value.selfSupporting = undefined;
				value.dependantReason = undefined;
			"
		>
			<yes-no-choice
				v-model="value.selfSupporting"
				label="Is the child self-supporting?"
				class="mb-3"
				:testid="testid + '-is-self-supporting'"
				:disabled="disabled"
			/>

			<conditional-group :condition="value.selfSupporting === false">
				<input-group
					v-model="value.dependantReason"
					input-cols="8"
					label="Child remains dependant because:"
					reset-undefined
					:testid="testid + '-dependant-reason'"
					:disabled="disabled"
				/>

				<check-group
					v-model="value.applicableChildSupportAspects"
					:label="`What Child Support aspects are applicable for ${childPreferredName}`"
					class="mt-3"
					:options="[
						{ text: 'Table Amount', value: 'table-amount', attrs:{testid: testid+value} },
						{
							text: 'Special or Extraordinary Expenses',
							value: 'special-extraordinary-expenses',
						},
					]"
					:testid="testid + '-child-support-aspects'"
					:id="testid + '-child-support-aspects'"
					:disabled="disabled"
				/>
			</conditional-group>
		</conditional-group>
	</div>
</template>
