<template><div></div></template>
<script>
    export default {
   name:"Intercom",
	methods: {
        appendIntercomScript(){
            const VUE_APP_INTERCOM_APP_ID = process.env.VUE_APP_INTERCOM_APP_ID; 
            
            let headScript = document.createElement('script');
            headScript.innerHTML = `(function(){var w=window;
                                    var ic=w.Intercom;
                                    if(typeof ic==="function"){
                                    ic('reattach_activator');
                                    ic('update',w.intercomSettings);
                                    }else{
                                    var d=document;
                                    var i=function(){
                                    i.c(arguments);
                                    };
                                    i.q=[];
                                    i.c=function(args){i.q.push(args);
                                    };
                                    w.Intercom=i;
                                    var l=function(){var s=d.createElement('script');
                                    s.type='text/javascript';
                                    s.async=true;s.src='https://widget.intercom.io/widget/' + '${VUE_APP_INTERCOM_APP_ID}';
                                    var x=d.getElementsByTagName('script')[0];
                                    x.parentNode.insertBefore(s,x);};
                                    if(w.attachEvent){
                                        w.attachEvent('onload',l);
                                    }else{
                                        w.addEventListener('load',l,false);
                                    }}})();`
            document.head.appendChild(headScript);

            let bodyScript = document.createElement('script');
            bodyScript.innerText = `window.Intercom('boot', {app_id: '${VUE_APP_INTERCOM_APP_ID}'});`
            document.body.appendChild(bodyScript);
            
            }

    },
    mounted(){
        this.$root.$on('appendIntercomScript', () => {
            this.appendIntercomScript()
    })
    }
};


</script>

