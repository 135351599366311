import add from 'lodash/add';
import toNumber from 'lodash/toNumber';

import captialGains from '@/lib/capitalGains';
import calculateExclusion from '@/lib/calculateExclusion';
import { getPartnerName } from '@/lib/agreement/filters';

export default function AdditionalProperty(
	{
		address,
		currentValue = 0,
		exclusions,
		keep,
		liabilities,
		ownership,
		propertyStatus,
		useCapitalGainsValue,
		factorInCapitalGains,
		capitalGainsOverride,
		purchasePrice,
		taxYearsPrincipalResidence,
		taxYearsOwned,
	},
	agreement,
	initialIndex
) {
	const sumOfLiabilities = liabilities.reduce(
		(total, { balance }) => add(total, toNumber(balance)),
		0
	);

	const capitalGains = factorInCapitalGains
		? useCapitalGainsValue
			? captialGains(
					currentValue,
					purchasePrice,
					taxYearsPrincipalResidence,
					taxYearsOwned
			  )
			: capitalGainsOverride
		: 0;
	const value = toNumber(currentValue) - sumOfLiabilities - capitalGains;

	const spouceOneExclusions = toNumber(calculateExclusion(exclusions, '0'));
	const spouceTwoExclusions = toNumber(calculateExclusion(exclusions, '1'));
	const shared = value - spouceOneExclusions - spouceTwoExclusions;

	let spouseOne = 0,
		spouseTwo = 0,
		enabled = false;
	if(agreement.agreement.propertyRecitals.length>0 && agreement.agreement.propertyRecitals.includes('Additional Real Property')){
		enabled=true
	}else{
		enabled=false
	}
	if (propertyStatus == 'keep') {
		if (keep == '0') {
			spouseOne = shared;
		} else {
			spouseTwo = shared;
		}
	} else {
		const half = shared / 2;
		spouseOne = half;
		spouseTwo = half;
	}
	const ownershipValue = getPartnerName(ownership, agreement);

	return {
		description:
			address && Object.keys(address).length
				? `${address.street || ''}, ${address.city || ''}, ${
						address.province || ''
				  }`
				: '',
		ownership: ownershipValue == 'Jointly' ? 'Joint' : ownershipValue,
		notes: [
			{ label: 'Fair Market Value', value: currentValue },
			...liabilities.map(
				({ balance, lastFourDigits, lender, registered, type }) => ({
					label: `${lender} ${
						registered === 'other' ? (type || '') : registered
					} ${lastFourDigits && `[${lastFourDigits}]`}`,
					value: balance,
				})
			),
			{ label: 'Equity', value, class: 'border-top-1' },
		],
		value,
		spouceOneExclusions,
		spouceTwoExclusions,
		shared,
		spouseOne,
		spouseTwo,
		type:'additional-property',
		enabled,
		initialIndex
	};
}
