import partnerNames from '@/mixins/partnerNames';

export default {
	mixins: [partnerNames],
	computed: {
		partnerChoices() {
			return [
				{
					value: '0',
					text: this.partner1Name,
				},
				{
					value: '1',
					text: this.partner2Name,
				},
			];
		},
	},
};
