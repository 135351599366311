import axios from 'axios';
import { auth } from '../firebase';

export default function authenticatedRequest(method, url, data) {
	if (!auth.currentUser) {
		throw new Error("Not authenticated. Make sure you're signed in!");
	}

	// Get the Firebase auth token to authenticate the request
	const base =
		process.env.NODE_ENV !== 'production'
			? 'http://localhost:5001/divii-development/northamerica-northeast1/api'
			: `https://northamerica-northeast1-${process.env.VUE_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api`; //`${location.origin}/api`

	return auth.currentUser.getIdToken().then(function (token) {
		var request = {
			method,
			url: `${base}${url}`,
			dataType: 'json',
			headers: {
				Authorization: 'Bearer ' + token,
			},
		};

		if (method === 'POST') {
			request.contentType = 'application/json';
			request.data = data;
		}

		return axios(request).catch(function () {
			throw new Error('Request error: ' + method + ' ' + url);
		});
	});
}
