<script>
import conditionalGroupReset from '@/mixins/conditionalGroupReset';
import { BIconInfoCircle,BTooltip } from 'bootstrap-vue';
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import agreementValidation from '@/mixins/agreementValidation'
export default {
	mixins: [conditionalGroupReset,validationMixin,agreementValidation],
	components:{BIconInfoCircle,BTooltip},
	props: {
		label: String,
		value: Boolean,
		options: {
			type: Array,
			default() {
				return [
					{ value: true, text: 'Yes' },
					{ value: false, text: 'No' },
				];
			},
		},
		cols: {
			type: String,
			default() {
				return '2';
			},
		},
		testid:String,
		onChange: Function,
		disabled:Boolean,
		toolTipText:String,
		isValidationOn:Boolean
	},
	data() {
		return {
			localValue: null,
			showToolTip:true
		};
	},
	watch: {
		localValue() {
			this.$emit('input', this.localValue);
		},
	},
	mounted() {
		this.updateValue();
	},
	methods: {
		updateValue() {
			this.localValue = this.value;
		},
		onChangeByUser(event){
			this.$emit('changeValue', event);
		},
		validateState(){
			const { $dirty, $error } = this.$v.localValue;
			return $dirty ? !$error : null;
		},
		triggerValidation(){
			this.$v.$touch()
		}
	},
	validations:{
		localValue:{
			required
		}
	}
};
</script>

<template>
	<b-form-group :label="label" :class="{'field-required-group': isValidationOn}">
		<b-row no-gutters>
			<b-col :class="'col-6 col-sm-'+cols">
				<b-form-select
					v-model="$v.localValue.$model"
					:options="options"
					:on-change="onChange"
					:data-testid="testid"
					@change="onChangeByUser"
					:disabled="disabled"
					:state="isValidationOn? validateState(): null"
				/>
				<div
					v-if="$v.localValue.$error && isValidationOn"
					class="invalid-feedback d-block col-12"
				>
					This is a required field.
				</div>
			</b-col>
			<b-col :cols="cols" class="info-outer" v-if="toolTipText">
				<b-icon-info-circle id="tooltip-target-yesnochoice"></b-icon-info-circle>
				<b-tooltip target="tooltip-target-yesnochoice">
					{{toolTipText}}
				</b-tooltip>
			</b-col>
		</b-row>
	</b-form-group>
</template>

<style scoped>
.info-outer {
	align-self: center;
    margin-left: 0.5rem;
}
</style>
