import toNumber from 'lodash/toNumber';

import calculateExclusion from '@/lib/calculateExclusion';
import { getPartnerName } from '@/lib/agreement/filters';

export default function RRSP(
	{ name = '', ownership, RRSPvalue = 0, lastFourDigits = '', exclusions , discountRRSPForTax, taxRateToApplyRRSP},
	agreement,
	index
) {
	const spouceOneExclusions = calculateExclusion(exclusions, '0');
	const spouceTwoExclusions = calculateExclusion(exclusions, '1');
	const calculatedRRSPValue = (discountRRSPForTax && taxRateToApplyRRSP) ?  (RRSPvalue * ((100 - toNumber(taxRateToApplyRRSP))/100)) : RRSPvalue
	const shared =
		toNumber(calculatedRRSPValue) - spouceOneExclusions - spouceTwoExclusions;
	let spouseOne = 0,
		spouseTwo = 0,
		enabled = false;
	if(agreement.agreement.propertyRecitals.length>0 && agreement.agreement.propertyRecitals.includes('RRSPs')){
		enabled=true
	}else{
		enabled=false
	}
	if (ownership == '0') {
		spouseOne = shared;
	} else if (ownership == '1') {
		spouseTwo = shared;
	}

	return {
		description: name
			? `${name} RRSP ${lastFourDigits && `[${lastFourDigits}]`}`
			: '',
		ownership: getPartnerName(ownership, agreement),
		comments: '',
		value: toNumber(calculatedRRSPValue),
		spouceOneExclusions,
		spouceTwoExclusions,
		shared,
		spouseOne,
		spouseTwo,
		type:'RRSP',
		enabled,
		discountRRSPForTax,
		initialIndex : index
	};
}
