<script>
import partnerChoices from '@/mixins/partnerChoices';

export default {
	mixins: [partnerChoices],
	props: {
		value: {
			type: Array,
			default: () => [],
		},
		testid: String,
		index: Number,
		hideForEdit:Boolean,
		disabled:Boolean
	},
	computed: {
		fields() {
			return [
				{ key: 'shareholder', heading: 'Shareholder' },
				{
					key: 'proportion',
					heading: 'Proportion of Family Owned Shares',
				},
				{ key: 'plan', heading: 'Plan for these shares' },
			];
		},
	},
};
</script>

<template>
	<table-input
		v-model="value"
		:fields="[
			{ key: 'shareholder', heading: 'Shareholder' },
			{
				key: 'proportion',
				heading: 'Proportion of Family Owned Shares',
			},
			{ key: 'plan', heading: 'Plan for these shares' },
		]"
		:max-rows="2"
		:testid="'corporation-exclusion-' + testid + '-'"
		:hideForEdit="hideForEdit"
		:disabled="disabled"
	>
		<template #shareholder="{ value, input, index }">
			<b-form-select
				size="sm"
				:options="[...partnerChoices]"
				:value="value"
				@input="input"
				:data-testid="
					'corporation-' + testid + '-partner-shares-' + index
				"
				:disabled="disabled"
			/>
		</template>

		<template #plan="{ value, input, index }">
			<b-form-select
				size="sm"
				:options="[
					{ text: 'Keep their shares', value: 'keep' },
					{
						text: 'Transfer their shares to the other party',
						value: 'transfer',
					},
					{
						text: 'Rollover their shares to a new company',
						value: 'rollover',
					},
				]"
				:value="value"
				@input="input"
				:data-testid="
					'corporation-' + testid + '-plans-for-shares-' + index
				"
				:disabled="disabled"
			/>
		</template>

		<template #proportion="{ value, input, index }">
			<b-input-group size="sm" append="%">
				<b-form-input
					size="sm"
					type="number"
					:value="value"
					@change="input"
					:data-testid="
						'corporation-' + testid + '-proportion-' + index
					"
					:disabled="disabled"
				/>
			</b-input-group>
		</template>
	</table-input>
</template>
<style scoped>
.select-class {
	height: calc(1.5em + 1rem + 2px);
}
.select-class:enabled:hover {
	border-color: #2196f3;
}
</style>
