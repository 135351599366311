import add from 'lodash/add';
import toNumber from 'lodash/toNumber';

import calculateExclusion from '@/lib/calculateExclusion';
import { getPartnerName } from '@/lib/agreement/filters';

export default function Corporation(corporation, agreement, initialIndex) {
	const {
		name = '',
		valuation = 0,
		exclusions,
		shares = [],
		rolloverAmount,
	} = corporation;
	const spouceOneExclusions = calculateExclusion(exclusions, '0');
	const spouceTwoExclusions = calculateExclusion(exclusions, '1');

	const shared = valuation;
	let spouseOne = 0,
		spouseTwo = 0,
		enabled = false;
	if(agreement.agreement.propertyRecitals.length>0 && agreement.agreement.propertyRecitals.includes('Corporation')){
		enabled=true
	}else{
		enabled=false
	}
	const { spouseOnePortion, spouseTwoPortion } = shares.reduce(
		(accum, share) => {
			if (share.plan == 'rollover') {
				let spouseOneSharePortion,
					spouseTwoSharePortion = 0;
				if (share.shareholder === '0') {
					spouseOneSharePortion =
						(toNumber(share.proportion) *
							(100 - toNumber(rolloverAmount))) /
						100;
					spouseTwoSharePortion =
						(toNumber(share.proportion) *
							toNumber(rolloverAmount)) /
						100;
				} else {
					spouseOneSharePortion =
						(toNumber(share.proportion) *
							toNumber(rolloverAmount)) /
						100;
					spouseTwoSharePortion =
						(toNumber(share.proportion) *
							(100 - toNumber(rolloverAmount))) /
						100;
				}
				accum.spouseOnePortion = add(
					accum.spouseOnePortion,
					toNumber(spouseOneSharePortion)
				);
				accum.spouseTwoPortion = add(
					accum.spouseTwoPortion,
					toNumber(spouseTwoSharePortion)
				);
			} else if (
				(share.shareholder === '0' && share.plan != 'transfer') ||
				(share.shareholder === '1' && share.plan == 'transfer')
			) {
				accum.spouseOnePortion = add(
					accum.spouseOnePortion,
					toNumber(share.proportion)
				);
			} else {
				accum.spouseTwoPortion = add(
					accum.spouseTwoPortion,
					toNumber(share.proportion)
				);
			}
			return accum;
		},
		{ spouseOnePortion: 0, spouseTwoPortion: 0 }
	);

	spouseOne = toNumber(valuation) * (spouseOnePortion / 100);
	spouseTwo = toNumber(valuation) * (spouseTwoPortion / 100);
	const value = toNumber(valuation);

	// Detials is what is shown in the prop schedule, all the math is at the corp level.
	return {
		description: name,
		ownership: '',
		comments: '',
		value,
		spouceOneExclusions,
		spouceTwoExclusions,
		shared,
		spouseOne: spouseOne - spouceOneExclusions,
		spouseTwo: spouseTwo - spouceTwoExclusions,
		type:'corporation',
		enabled,
		details: rolloverAmount
			? [
					{
						description: 'Outstanding Shares',
						descriptionStyle: 'padding-left: 18px !important;', // Bump out Outstanding shares relevant to corp
						ownership: getPartnerName(
							shares[0].shareholder,
							agreement
						),
						comments: `${shares[0].proportion}%`,
						value,
						spouceOneExclusions,
						spouceTwoExclusions,
						shared,
						spouseOne:
							shares[0].shareholder == '0'
								? (value *
										(shares[0].proportion *
											((100 - toNumber(rolloverAmount)) /
												100))) /
										100 -
								  spouceOneExclusions
								: (value *
										(shares[0].proportion *
											(toNumber(rolloverAmount) / 100))) /
										100 -
								  spouceOneExclusions,

						spouseTwo:
							shares[0].shareholder == '1'
								? (value *
										(shares[0].proportion *
											((100 - toNumber(rolloverAmount)) /
												100))) /
										100 -
								  spouceTwoExclusions
								: (value *
										(shares[0].proportion *
											(toNumber(rolloverAmount) / 100))) /
										100 -
								  spouceTwoExclusions,
						keeper: shares[0].shareholder,
					},
			  ]
			: shares.map(({ proportion, shareholder, plan }) => {
					const spouceOneShareExclusions =
						shares.length === 1
							? spouceOneExclusions
							: shareholder === '0'
							? spouceOneExclusions
							: 0;
					const spouceTwoShareExclusions =
						shares.length === 1
							? spouceTwoExclusions
							: shareholder === '1'
							? spouceTwoExclusions
							: 0;
					const value = toNumber(valuation) * (proportion / 100);
					const shareValue =
						value -
						spouceOneShareExclusions -
						spouceTwoShareExclusions;
					return {
						description: 'Outstanding Shares',
						descriptionStyle: 'padding-left: 18px !important;',
						ownership: getPartnerName(shareholder, agreement),
						comments: `${proportion}%`,
						value,
						shared: shareValue,
						spouceOneExclusions: spouceOneShareExclusions,
						spouceTwoExclusions: spouceTwoShareExclusions,
						spouseOne:
							(shareholder === '0' && plan == 'transfer') ||
							(shareholder === '1' && plan != 'transfer')
								? 0
								: shareValue,
						spouseTwo:
							(shareholder === '1' && plan == 'transfer') ||
							(shareholder === '0' && plan != 'transfer')
								? 0
								: shareValue,
						keeper:
							(shareholder === '0' && plan == 'transfer') ||
							(shareholder === '1' && plan != 'transfer')
								? '1'
								: '0',
					};
			  }),
		initialIndex
	};
}
