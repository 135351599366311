import {getPartnerName} from '@/lib/agreement/filters';
import { pureFilters } from '@/lib/agreement/filters';

const holidayMap = [{
		decision: 'famDayDecision',
		label: 'Family Day',
		text: 'familyDayText',
		value: 'familyDay',
		type: 'holiday',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
	{
		decision: 'goodFridayDecision',
		label: 'Good Friday',
		text: 'goodFridayText',
		value: 'goodFriday',
		type: 'holiday',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
	{
		decision: 'easterMondayDecision',
		label: 'Easter Monday',
		text: 'easterMondayText',
		value: 'easterMonday',
		type: 'holiday',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
	{
		decision: 'victoriaDayDecision',
		label: 'Victoria Day',
		text: 'victoriaDayText',
		value: 'victoriaDay',
		type: 'holiday',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
	{
		decision: 'cananaDayDecision',
		label: 'Canada Day',
		text: 'canadaDayText',
		value: 'canadaDay',
		type: 'holiday',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
	{
		decision: 'bcDecision',
		label: 'B.C. Day',
		text: 'bcDayText',
		value: 'bcDay',
		type: 'holiday',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
	{
		decision: 'labourDayDecision',
		label: 'Labour Day',
		text: 'labourDayText',
		value: 'labourDay',
		type: 'holiday',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
	{
		decision: 'truthDayDecision',
		label: 'National Day for Truth and Reconciliation',
		text: 'truthDayText',
		value: 'truthDayRecon',
		type: 'holiday',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
	{
		decision: 'thanksgivingDecision',
		label: 'Thanksgiving Day',
		text: 'thanksgivingText',
		value: 'thanksgiving',
		type: 'holiday',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
	{
		decision: 'remebranceDayDecision',
		label: 'Remembrance Day',
		text: 'remebranceDayText',
		value: 'remebranceDay',
		type: 'holiday',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
	{
		decision: 'christmasEveDecision',
		label: 'Christmas Eve',
		text: 'christmasEveText',
		value: 'christmasEve',
		type: 'holiday',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
	{
		decision: 'christmasDayDecision',
		label: 'Christmas Day',
		text: 'christmasDayText',
		value: 'christmasDay',
		type: 'holiday',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
	{
		decision: 'boxingDecision',
		label: 'Boxing Day',
		text: 'boxingText',
		value: 'boxing',
		type: 'holiday',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
	{
		decision: 'newYearsEveDecision',
		label: "New Year's Eve",
		value: 'newYearsEve',
		text: 'newYearsEveText',
		type: 'holiday',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
	{
		decision: 'newYearsDayDecision',
		label: "New Year's Day",
		text: 'newYearsDayText',
		value: 'newYearsDay',
		type: 'holiday',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
	{
		decision: 'prodDDayDecision',
		label: 'Professional Development Days',
		text: 'prodDDayText',
		value: 'prodDDay',
		type: 'holiday',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
	{
		decision: 'winterDecision1',
		label: 'The first half of Winter Break',
		text: 'winterText1',
		value: 'winterBreak1',
		type: 'schoolBreak',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
	{
		decision: 'winterDecision2',
		label: 'The second half of Winter Break',
		text: 'winterText2',
		value: 'winterBreak2',
		type: 'schoolBreak',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
	{
		decision: 'springBreakDecision1',
		label: 'The first half of Spring Break',
		text: 'springBreakText1',
		value: 'springBreak1',
		type: 'schoolBreak',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
	{
		decision: 'springBreakDecision2',
		label: 'The second half of Spring Break',
		text: 'springBreakText2',
		value: 'springBreak2',
		type: 'schoolBreak',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
	{
		decision: 'summerBreakDecision',
		label: 'Summer Break',
		text: 'summerBreakText',
		value: 'summerBreak',
		type: 'schoolBreak',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
	{
		decision: 'otherDecision',
		label: 'Other',
		text: 'otherText',
		value: 'other',
		type: 'schoolBreak',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
	{
		decision: 'mothersDayDecision',
		label: "Mother's Day",
		text: 'mothersDayText',
		value: 'mothersDay',
		type: 'specialDays',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
	{
		decision: 'fathersDayDecision',
		label: "Father's Day",
		text: 'fathersDayText',
		value: 'fathersDay',
		type: 'specialDays',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
	// {
	// 	decision: 'childBirthdaysDecision',
	// 	label: "Children's Birthdays",
	// 	text: 'childBirthdaysText',
	// 	value: 'childBirthdays',
	// 	type: 'specialDays',
	//	holidayDecisionSpecifyChange: '',
	//	partyWithParentingTimeOnOddYears: '',
	//	partyWithParentingTimeEveryYear: '',
	//	decisionSpecifyChangeOtherValue: '',
	//	holidayTransitionTimesAgreedBetweenParties: '',
	//	holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	//},
	{
		decision: 'halloweenDecision',
		label: 'Halloween',
		text: 'halloweenText',
		value: 'halloween',
		type: 'specialDays',
		holidayDecisionSpecifyChange: '',
		partyWithParentingTimeOnOddYears: '',
		partyWithParentingTimeEveryYear: '',
		decisionSpecifyChangeOtherValue: '',
		holidayTransitionTimesAgreedBetweenParties: '',
		holidayTransitionTimesAgreedBetweenPartiesOtherValue: ''
	},
];

function holidays(agreement) {
	const birthdays = [{
			decision: 'partner1BirthdayDecision',
			label: `${getPartnerName('0', agreement)}'s Birthday`,
			text: 'partner1BirthdayText',
			value: 'partner1Birthday',
			type: 'specialDays',
			holidayDecisionSpecifyChange: '',
			partyWithParentingTimeOnOddYears: '',
			partyWithParentingTimeEveryYear: '',
			decisionSpecifyChangeOtherValue: '',
			holidayTransitionTimesAgreedBetweenParties: '',
			holidayTransitionTimesAgreedBetweenPartiesOtherValue: '',
			id:'partner1Birthday'
		},
		{
			decision: 'partner2BirthdayDecision',
			label: `${getPartnerName('1', agreement)}'s Birthday`,
			text: 'partner2BirthdayText',
			value: 'partner2Birthday',
			type: 'specialDays',
			holidayDecisionSpecifyChange: '',
			partyWithParentingTimeOnOddYears: '',
			partyWithParentingTimeEveryYear: '',
			decisionSpecifyChangeOtherValue: '',
			holidayTransitionTimesAgreedBetweenParties: '',
			holidayTransitionTimesAgreedBetweenPartiesOtherValue: '',
			id:'partner2Birthday'
		},
	];
	// const childrenNames = agreement.children.map(pureFilters.preferredName);

	agreement.children.forEach((obj) => {
		if (!obj.hasOwnProperty('key')) {
		  obj.key = Math.random();
		}
	  });
	const childrensBirthdays = agreement.children.map((child , index) => {
		const ChildName = pureFilters.preferredName(child); 
		let obj = {
			decision: `${ChildName}'sBirthdaysDecision`,
			label: `${ChildName}'s Birthday`,
			text: `${ChildName}BirthdaysText`,
			value: `${ChildName}Birthday`,
			type: 'specialDays',
			holidayDecisionSpecifyChange: '',
			partyWithParentingTimeOnOddYears: '',
			partyWithParentingTimeEveryYear: '',
			decisionSpecifyChangeOtherValue: '',
			holidayTransitionTimesAgreedBetweenParties: '',
			holidayTransitionTimesAgreedBetweenPartiesOtherValue: '',
			id: `child${index}'sBirthday`,
			key : child.key
		};

		return obj;
	});
   const updateHolidayPerProvince = updateHolidayPerProvinceChange(agreement,holidayMap); 
   const additionalHolidays = agreement.parenting.additionalHolidays ? agreement.parenting.additionalHolidays : []


	return [...updateHolidayPerProvince, ...birthdays, ...childrensBirthdays ,...additionalHolidays];
}

function updateHolidayPerProvinceChange(agreement,holidayMap){
	const provinceOfAgreement = agreement.agreement.updatedProvinceOfAgreement	? agreement.agreement.updatedProvinceOfAgreement.toLowerCase() : '';
	holidayMap = holidayMap.filter(item => !item.dynamic)
	if(provinceOfAgreement == 'newfoundland and labrador' || provinceOfAgreement == 'nunavut' || provinceOfAgreement == 'northwest territories'){
		holidayMap = holidayMap.filter(hol=> hol.value != 'familyDay')
	}
	// console.log('holidayMap legnth-->',holidayMap.length)
	if(provinceOfAgreement == 'newfoundland and labrador'){
		let indexToCheck = holidayMap.findIndex(hol=> hol.value == 'patrickDay');
		if(indexToCheck < 0){
			holidayMap.push({
				decision: 'patrickDayDecision',
				label: "St. Patrick's Day",
				text: 'patrickDayText',
				value: 'patrickDay',
				type: 'holiday',
				holidayDecisionSpecifyChange: '',
				partyWithParentingTimeOnOddYears: '',
				partyWithParentingTimeEveryYear: '',
				decisionSpecifyChangeOtherValue: '',
				holidayTransitionTimesAgreedBetweenParties: '',
				holidayTransitionTimesAgreedBetweenPartiesOtherValue: '',
				dynamic:true
			},{
				decision: 'georgeDayDecision',
				label: "St. George's Day",
				text: 'georgeDayText',
				value: 'georgeDay',
				type: 'holiday',
				holidayDecisionSpecifyChange: '',
				partyWithParentingTimeOnOddYears: '',
				partyWithParentingTimeEveryYear: '',
				decisionSpecifyChangeOtherValue: '',
				holidayTransitionTimesAgreedBetweenParties: '',
				holidayTransitionTimesAgreedBetweenPartiesOtherValue: '',
				dynamic:true
			},{
				decision: 'memorialDayDecision',
				label: "Memorial Day",
				text: 'memorialDayText',
				value: 'memorialDay',
				type: 'holiday',
				holidayDecisionSpecifyChange: '',
				partyWithParentingTimeOnOddYears: '',
				partyWithParentingTimeEveryYear: '',
				decisionSpecifyChangeOtherValue: '',
				holidayTransitionTimesAgreedBetweenParties: '',
				holidayTransitionTimesAgreedBetweenPartiesOtherValue: '',
				dynamic:true
			},{
				decision: 'twelfthDayDecision',
				label: "the Twelfth",
				text: 'twelfthDayText',
				value: 'twelfthDay',
				type: 'holiday',
				holidayDecisionSpecifyChange: '',
				partyWithParentingTimeOnOddYears: '',
				partyWithParentingTimeEveryYear: '',
				decisionSpecifyChangeOtherValue: '',
				holidayTransitionTimesAgreedBetweenParties: '',
				holidayTransitionTimesAgreedBetweenPartiesOtherValue: '',
				dynamic:true
			})
		}
	} else if(provinceOfAgreement == 'northwest territories' || provinceOfAgreement == 'yukon'){
		let indexToCheck = holidayMap.findIndex(hol=> hol.value == 'nationalIndigenousPeoplesDay');
		if(indexToCheck < 0){
			holidayMap.push({
				decision: 'nationalIndigenousPeoplesDayDecision',
				label: "National Indigenous Peoples Day",
				text: 'nationalIndigenousPeoplesDayText',
				value: 'nationalIndigenousPeoplesDay',
				type: 'holiday',
				holidayDecisionSpecifyChange: '',
				partyWithParentingTimeOnOddYears: '',
				partyWithParentingTimeEveryYear: '',
				decisionSpecifyChangeOtherValue: '',
				holidayTransitionTimesAgreedBetweenParties: '',
				holidayTransitionTimesAgreedBetweenPartiesOtherValue: '',
				dynamic:true
			})
		}
	} else if(provinceOfAgreement == 'nunavut'){
		let indexToCheck = holidayMap.findIndex(hol=> hol.value == 'nunavutDay');
		if(indexToCheck < 0){
			holidayMap.push({
				decision: 'nunavutDayDecision',
				label: "Nunavut Day",
				text: 'nunavutDayText',
				value: 'nunavutDay',
				type: 'holiday',
				holidayDecisionSpecifyChange: '',
				partyWithParentingTimeOnOddYears: '',
				partyWithParentingTimeEveryYear: '',
				decisionSpecifyChangeOtherValue: '',
				holidayTransitionTimesAgreedBetweenParties: '',
				holidayTransitionTimesAgreedBetweenPartiesOtherValue: '',
				dynamic:true
			})
		}
	} else if(provinceOfAgreement == 'prince edward island'){
		let indexToCheck = holidayMap.findIndex(hol=> hol.value == 'goldCupDay');
		if(indexToCheck < 0){
			holidayMap.push({
				decision: 'goldCupDayDecision',
				label: "Gold Cup Parade",
				text: 'goldCupText',
				value: 'goldCupDay',
				type: 'holiday',
				holidayDecisionSpecifyChange: '',
				partyWithParentingTimeOnOddYears: '',
				partyWithParentingTimeEveryYear: '',
				decisionSpecifyChangeOtherValue: '',
				holidayTransitionTimesAgreedBetweenParties: '',
				holidayTransitionTimesAgreedBetweenPartiesOtherValue: '',
				dynamic:true
			})
		}
	} 
	if(provinceOfAgreement == 'yukon'){
		let indexToCheck = holidayMap.findIndex(hol=> hol.value == 'discoveryDay');
		if(indexToCheck < 0){
			holidayMap.push({
				decision: 'discoveryDayDecision',
				label: "Discovery Day",
				text: 'discoveryDayText',
				value: 'discoveryDay',
				type: 'holiday',
				holidayDecisionSpecifyChange: '',
				partyWithParentingTimeOnOddYears: '',
				partyWithParentingTimeEveryYear: '',
				decisionSpecifyChangeOtherValue: '',
				holidayTransitionTimesAgreedBetweenParties: '',
				holidayTransitionTimesAgreedBetweenPartiesOtherValue: '',
				dynamic:true
			})
		}
	}

	let bcDayIndex = holidayMap.findIndex(hol=> hol.value == 'bcDay');
	if(bcDayIndex > -1) {
		switch (provinceOfAgreement) {
			case 'alberta':
				holidayMap[bcDayIndex].label='Heritage Day';
				break;
			case 'saskatchewan':
				holidayMap[bcDayIndex].label='Saskatchewan Day';
				break;
			case 'manitoba':
				holidayMap[bcDayIndex].label='Terry Fox Day';
				break;
			case 'ontario':
				holidayMap[bcDayIndex].label='August Civic Holiday';
				break;
			case 'newfoundland and labrador':
				holidayMap[bcDayIndex].label='August Civic Holiday';
				break;
			case 'new brunswick':
				holidayMap[bcDayIndex].label='New Brunswick Day';
				break;
			case 'nova scotia':
				holidayMap[bcDayIndex].label='August Civic Holiday'
				break;
			case 'prince edward island':
				holidayMap[bcDayIndex].label='August Civic Holiday';
				break;
			case 'nunavut':
				holidayMap[bcDayIndex].label='August Civic Holiday';
				break;
			case 'northwest territories':
				holidayMap[bcDayIndex].label='August Civic Holiday';
				break;
			case 'yukon':
				holidayMap[bcDayIndex].label='August Civic Holiday';
				break;
			default:
				break;
		}
	}
	return holidayMap;
}

export default holidays;