<script>
import { BIconX } from 'bootstrap-vue';
import fromPairs from 'lodash/fromPairs';
import map from 'lodash/map';

export default {
	components: {
		BIconX,
	},
	props: {
		fields: Array,
		value: Array,
		maxRows: Number,
		testid:String,
		hideForEdit:Boolean,
		disabled:Boolean
	},
	data() {
		return {};
	},
	methods: {
		add() {
			this.value.push(this.newItem());
		},
		changeValue(index, field) {
			return (val) => (this.value[index][field] = val);
		},
		newItem() {
			const keys = map(this.fields, 'key');
			const obj = fromPairs(map(keys, (k) => [k, '']));

			return obj;
		},
		remove(id) {
			this.value.splice(id, 1);
		},
	},
};
</script>

<template>
	<b-table-simple small>
		<b-thead>
			<b-tr>
				<b-th v-for="field in fields" :key="field.key">{{
					field.heading
				}}</b-th>
				<b-th />
			</b-tr>
		</b-thead>
		<b-tbody>
			<b-tr v-for="(row, index) in value" :key="index">
				<b-td v-for="field in fields" :key="field.key" class="b-td">
					<slot
						:name="field.key"
						:value="row[field.key]"
						:row="row"
						:input="changeValue(index, field.key)"
						:index="index"
						:data-testid="testid+index"
					/>
				</b-td>
				<b-td class="remove" v-if="!hideForEdit && !disabled">
					<b-icon-x class="border rounded remove-icon" @click="remove(index)" />
				</b-td>
			</b-tr>
		</b-tbody>

		<b-tfoot v-if="(maxRows && maxRows > value.length) || !maxRows">
			<b-tr>
				<b-td colspan="4" class="text-right">
					<b-button size="sm" @click="add" :data-testid="testid+'add-fields'" :disabled="disabled" variant="pastel-blue">Add</b-button>
				</b-td>
			</b-tr>
		</b-tfoot>
	</b-table-simple>
</template>
<style scoped>
.b-td{
	width: 33%;
}
.remove{
	vertical-align: middle;
}
.remove-icon{
	height: 1.3rem;
	width: 1.3rem;
}
</style>
