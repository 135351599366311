export default {
	// Copy

	// Feature Flags
	feature_summary: false,
	feature_documents: false,
	feature_test_data: false,
	feature_assistant_management: false,
	feature_childSupport: false,

	// Access
	staging: false,
};
