<script>
export default {
	model: {
		prop: 'checked',
	},
	props: {
		label: String,
		options: Array,
		checked: Array,
		testid:String,
		id:String,
		disabled: Boolean
	},
	methods: {
		emitInput(checked) {
			this.$emit('input', checked);
		},
	},
};
</script>

<template>
	<b-form-group :label="label" :disabled="disabled">
		<b-form-checkbox-group stacked :checked="checked" @input="emitInput">
			<div v-for="(option, index) in options" :key="index">
				<b-form-checkbox :value="option" :data-testid="testid+index" :id="id+index">{{ option }}</b-form-checkbox>
				<conditional-group
					v-if="$slots[option]"
					:condition="checked && checked.includes(option)"
					class="my-2"
					:testid="testid"
				>
					<slot :name="option" />
				</conditional-group>
			</div>
		</b-form-checkbox-group>
	</b-form-group>
</template>
:
