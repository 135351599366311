import toNumber from 'lodash/toNumber';

import { getPartnerName } from '@/lib/agreement/filters';

export default function Pension(
	{ name = '', ownership, value = 0, plan = '', partiesAssignValue, orderOfTax },
	agreement,
	initialIndex
) {
	const shared = toNumber(value);
	let spouseOne = 0,
		spouseTwo = 0,
		keeper = null,
		enabled = false;
	if(agreement.agreement.propertyRecitals.length>0 && agreement.agreement.propertyRecitals.includes('Pensions')){
		enabled=true
	}else{
		enabled=false
	}
	if (plan.toLowerCase() == 'keep') {
		if (ownership == '0') {
			spouseOne = shared;
			keeper = '0';
		} else if (ownership == '1') {
			spouseTwo = shared;
			keeper = '1';
		} else {
			const half = shared / 2;
			spouseOne = half;
			spouseTwo = half;
			keeper = 'joint';
		}
	} else {
		const half = shared / 2;
		spouseOne = half;
		spouseTwo = half;
		keeper = 'joint';
	}
	return {
		description: name,
		ownership: getPartnerName(ownership, agreement),
		comments: '',
		value: partiesAssignValue ? value : '-',
		spouceOneExclusions: '',
		spouceTwoExclusions: '',
		shared: partiesAssignValue ? shared : '-', // this only works because there are no exclusions
		spouseOne,
		spouseTwo,
		keeper,
		type:'pension',
		enabled,
		initialIndex,
		orderOfTax,
		partiesAssignValue
	};
}
