<script>
import partnerChoices from '@/mixins/partnerChoices';

export default {
	mixins: [partnerChoices],
	props: {
		label: String,
		value: String,
		additionalChoices: {
			type: Array,
			default: () => [],
		},
		agreementData: {
			type: Object,
			required: true,
		},
		testid:String,
		disabled:Boolean
	},
	computed: {
		options() {
			return [...this.partnerChoices, ...this.additionalChoices];
		},
	},
};
</script>

<template>
	<radio-group
		:value="value"
		:label="label"
		:options="options"
		@input="$emit('input', arguments[0])"
		:testid="testid"
		:length="'long'"
		:disabled="disabled"
	/>
</template>
