import add from 'lodash/add';
import divide from 'lodash/divide';
import multiply from 'lodash/multiply';
import round from 'lodash/round';
import subtract from 'lodash/subtract';

/**
 * YPR = Years Property was used as Principal Residence
 * YO – Years owned
 *
 * Figure out the gain then subtract the principal residence exemption.
 * That figures out the overall capital gain.
 * Then only 1/2 of the capital gain is taxable.
 * Then you apply the tax rate.
 *
 * (((currentValue-purchasePrice)-(( currentValue-purchasePrice)*((1+yearsPrincipleResidence)/yearsOwned)))*0.5)*0.35;
 *
 * @param {number} currentValue
 * @param {number} purchasePrice
 * @param {number} yearsPrincipleResidence
 * @param {number} yearsOwned
 *
 * @return {number} captial gains from calulation;
 */
export default (
	currentValue,
	purchasePrice,
	yearsPrincipleResidence,
	yearsOwned
) => {
	const capitalGains = round(
		multiply(
			multiply(
				subtract(
					subtract(currentValue, purchasePrice),
					multiply(
						subtract(currentValue, purchasePrice),
						divide(add(1, yearsPrincipleResidence), yearsOwned)
					)
				),
				0.5
			),
			0.35
		),
		2
	);
	return capitalGains >= 0 ? capitalGains : 0;
};
