var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-input',{attrs:{"fields":[
		{ key: 'shareholder', heading: 'Shareholder' },
		{
			key: 'proportion',
			heading: 'Proportion of Family Owned Shares',
		},
		{ key: 'plan', heading: 'Plan for these shares' } ],"max-rows":2,"testid":'corporation-exclusion-' + _vm.testid + '-',"hideForEdit":_vm.hideForEdit,"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"shareholder",fn:function(ref){
	var value = ref.value;
	var input = ref.input;
	var index = ref.index;
return [_c('b-form-select',{attrs:{"size":"sm","options":[].concat( _vm.partnerChoices ),"value":value,"data-testid":'corporation-' + _vm.testid + '-partner-shares-' + index,"disabled":_vm.disabled},on:{"input":input}})]}},{key:"plan",fn:function(ref){
	var value = ref.value;
	var input = ref.input;
	var index = ref.index;
return [_c('b-form-select',{attrs:{"size":"sm","options":[
				{ text: 'Keep their shares', value: 'keep' },
				{
					text: 'Transfer their shares to the other party',
					value: 'transfer',
				},
				{
					text: 'Rollover their shares to a new company',
					value: 'rollover',
				} ],"value":value,"data-testid":'corporation-' + _vm.testid + '-plans-for-shares-' + index,"disabled":_vm.disabled},on:{"input":input}})]}},{key:"proportion",fn:function(ref){
			var value = ref.value;
			var input = ref.input;
			var index = ref.index;
return [_c('b-input-group',{attrs:{"size":"sm","append":"%"}},[_c('b-form-input',{attrs:{"size":"sm","type":"number","value":value,"data-testid":'corporation-' + _vm.testid + '-proportion-' + index,"disabled":_vm.disabled},on:{"change":input}})],1)]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})}
var staticRenderFns = []

export { render, staticRenderFns }