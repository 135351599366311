<script>
export default {
	props: {
		value: Boolean,
		label: String,
		testid:String,
	},
	data() {
		return {
			localValue: undefined,
		};
	},
	watch: {
		localValue() {
			this.$emit('input', this.localValue);
		},
	},
	mounted() {
		this.localValue = this.value;
	},
};
</script>

<template>
	<b-form-checkbox v-model="localValue" :data-testid="testid">{{ label }}</b-form-checkbox>
</template>
