import toNumber from 'lodash/toNumber';

import calculateExclusion from '@/lib/calculateExclusion';
import { getPartnerName } from '@/lib/agreement/filters';

export default function Business(
	{ name = '', ownership, valuation = 0, exclusions, plan = '',transferor },
	agreement,
	initialIndex
) {
	const spouceOneExclusions = calculateExclusion(exclusions, '0');
	const spouceTwoExclusions = calculateExclusion(exclusions, '1');
	const shared = valuation - spouceOneExclusions - spouceTwoExclusions;
	let spouseOne = 0,
		spouseTwo = 0,
		enabled = false;
	if(agreement.agreement.propertyRecitals.length>0 && agreement.agreement.propertyRecitals.includes('Business')){
		enabled=true
	}else{
		enabled=false
	}
	if (plan.toLowerCase() == 'keep') {
		if (ownership == '0') {
			spouseOne = shared;
		} else if (ownership == '1') {
			spouseTwo = shared;
		} else {
			const half = shared / 2;
			spouseOne = half;
			spouseTwo = half;
		}
	} else if (plan.toLowerCase() == 'transfer') {
		// swap
		if (ownership == '0') {
			spouseTwo = shared;
		} else if (ownership == '1') {
			spouseOne = shared;
		}
		else if(ownership == 'Jointly'){
			if(transferor == '0'){
				spouseTwo = shared;
			}else if(transferor == '1'){
				spouseOne = shared;
			}
		}
	} else {
		const half = shared / 2;
		spouseOne = half;
		spouseTwo = half;
	}

	return {
		description: name,
		ownership: getPartnerName(ownership, agreement),
		comments: '',
		value: toNumber(valuation),
		spouceOneExclusions,
		spouceTwoExclusions,
		shared,
		spouseOne,
		spouseTwo,
		type:'business',
		enabled,
		initialIndex
	};
}
