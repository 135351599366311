<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import agreementValidation from '@/mixins/agreementValidation'

export default {
	props: {
		label: String,
		value: Object,
		partnerId: String,
		testid: String,
		disabled: Boolean
	},
	mixins:[validationMixin,agreementValidation],
	methods:{
		validateState(name) {
			const { $dirty, $error } = this.$v.value.name[name];
			return $dirty ? !$error : null;
		},
		triggerValidation(){
			this.$v.$touch()
		}
	},
	validations:{
		value:{
			name:{
				first:{
					required
				},
				last:{
					required
				}
			}
		}
	}
};
</script>

<template>
	<div class="app-input-group party-input">
		<b-row>
			<b-col class="label name-input-label field-required">
				{{ label }}
			</b-col>
		</b-row>
		<b-row :id="partnerId" no-gutters>
			<b-col cols="4" class="input">
				<b-form-input
					:id="`${partnerId}_first_name`"
					v-model="$v.value.name.first.$model"
					name="first_name"
					placeholder="First name"
					:data-testid="testid+'-first-name'"
					:disabled="disabled"
					:state="validateState('first')"
				/>
				<div
					v-if="$v.value.name.first.$error"
					class="invalid-feedback d-block col-12"
				>
					This is a required field.
				</div>
			</b-col>
			<b-col cols="3" class="input">
				<b-form-input
					:id="`${partnerId}_middle_name`"
					v-model="value.name.middle"
					name="middle_name"
					placeholder="Middle name (Optional)"
					:data-testid="testid+'-middle-name'"
					:disabled="disabled"
				/>
			</b-col>
			<b-col cols="4" class="input">
				<b-form-input
					:id="`${partnerId}_last_name`"
					v-model="$v.value.name.last.$model"
					name="last_name"
					placeholder="Last Name"
					:data-testid="testid+'-last-name'"
					:disabled="disabled"
					:state="validateState('last')"
				/>
				<div
					v-if="$v.value.name.last.$error"
					class="invalid-feedback d-block col-12"
				>
					This is a required field.
				</div>
			</b-col>
		</b-row>
		<b-row no-gutters>
			<b-col cols="5" class="input col-11 col-sm-5">
				<b-form-input
					:id="`${partnerId}_preferred_name`"
					v-model="value.name.preferred"
					name="preferred_name"
					placeholder="Preferred name (Optional)"
					:data-testid="testid+'-preferred-name'"
					:disabled="disabled"
				/>
			</b-col>
		</b-row>
	</div>
</template>

<style></style>
