<script>
export default {
	props: {
		condition: Boolean,
		noBorder: Boolean,
		testid: String,
	},
	watch: {
		condition(val) {
			if (val === false) {
				this.$emit('reset');
				this.sendResetToChildren();
			}
		},
	},
	created() {
		this.$on('conditional-group-reset', () => this.sendResetToChildren());
	},
	methods: {
		sendResetToChildren() {
			this.$children.forEach((c) => c.$emit('conditional-group-reset'));
		},
	},
};
</script>

<template>
	<div v-if="condition" :class="noBorder ? '' : 'conditional-group'" v-bind="testid && { 'data-testid': testid + 'test' }">
		<slot />
	</div>
</template>

<style lang="scss">
.conditional-group {
	padding: 0 0.6em;
	border-left: 0.2em solid var(--primary);
}
</style>
